@import '../../styles/variables';

.XlnzReactGrid {
  //border: 1px solid red;
  text-align: left;
  box-sizing: border-box;
  font-size: 12px;

  //max-height: 800px;
  overflow: auto;

  $BORDER_WIDTH: 1px;
  $BORDER_COLOR: #dee2e6;
  $BORDER_COLOR_VERTICAL: #F1F6FA;
  $LINE_HEIGHT: 25px;
  $HORIZONTAL_PADDING: 5px;
  $VERTICAL_PADDING: 2px;
  $HOVER_BG_COLOR: #E9ECEF;

  > .header {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1;
    display: flex;

    div {
      background-color: #fff;
    }

    .uber-header-cell {
      //padding: 0 $HORIZONTAL_PADDING;
      border-top: $BORDER_WIDTH solid $BORDER_COLOR;
      //border-bottom: 2px solid $BORDER_COLOR;
      font-weight: bold;
      line-height: 30px;

      // Disable Text Selection
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */

      text-align: center;

      .sub-cols {
        display: flex;
      }
    }

    .header-cell {
      display: flex;
      align-items: center;

      padding: 0 $HORIZONTAL_PADDING;
      border-top: $BORDER_WIDTH solid $BORDER_COLOR;
      border-bottom: 2px solid $BORDER_COLOR;
      font-weight: bold;
      //line-height: $LINE_HEIGHT;
      min-height: 30px;

      // Disable Text Selection
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }

    .sort-active {
      color: #699b2c
    }

    .header-cell:hover {
      background-color: $HOVER_BG_COLOR;
    }

  }

  > .content {
    display: flex;
    flex-direction: column;

    .content-row {
      display: flex;
      cursor: pointer;

      .content-cell {
        display: flex;
        align-items: normal;

        padding: $VERTICAL_PADDING $HORIZONTAL_PADDING;
        border-bottom: $BORDER_WIDTH solid $BORDER_COLOR;
        border-right: $BORDER_WIDTH solid $BORDER_COLOR_VERTICAL;
        line-height: $LINE_HEIGHT;
        min-height: 30px;

        // Disable Text Selection
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */

        background-color: #fff;

        text-overflow: ellipsis !important;
        overflow: hidden !important;
      }
    }

    .content-cell.edit-mode {
      padding: 0 !important;
    }


    .content-row:hover {
      .content-cell {
        background-color: rgba(184, 207, 105, 0.2) !important;
      }
    }

    .current-drop {
      //background-color: red;
      border-top: 2px solid $color-green;
    }

    //.selected {
    //  background-color: lighten($color-lime, 20%);
    //}

    .pagination {
      text-align: center;
      //display: flex;
      //justify-content: center;
      margin-top: 20px;

      button {
        padding: 7px 12px;
        background-color: transparent;
        border: none;
        color: $color-green;
        font-size: 16px;
        cursor: pointer;
      }

      button:hover {;
        color: $color-dark-green;
      }

      .active {
        color: #fff;
        font-weight: bold;
        background-color: $color-green;
        border-radius: 10px;
      }

      button.active:hover {
        background-color: $color-green;
        color: #fff;
      }
    }
  }

  > .footer {
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    z-index: 1;
    display: flex;

    div {
      background-color: #fff;
    }

    .footer-cell {
      padding: 0px $HORIZONTAL_PADDING;
      border-top: $BORDER_WIDTH solid $BORDER_COLOR;
      border-bottom: $BORDER_WIDTH solid $BORDER_COLOR;
      border-right: $BORDER_WIDTH solid $BORDER_COLOR_VERTICAL;
      line-height: $LINE_HEIGHT;
      font-weight: bold;
    }
  }

  input[type='checkbox'] {
    cursor: pointer;
  }
}


